/*
 * @Author: chenzhi
 * @Date: 2023-03-01 16:38:17
 * @LastEditors: Chen Zhi
 * @LastEditTime: 2024-06-21 11:35:19
 * @Description: APP组件
 */
import routes from "@/components/router"
import { useRoutes } from "react-router-dom"
import { shallowEqual, useSelector } from "react-redux"
import { useEffect } from "react"

function App() {
  const { routs } = useSelector(
    (state: any) => ({ routs: state.login.routes }),
    shallowEqual
  )

  const element = useRoutes(routes)
  // 监听路由表改变重新渲染
  useEffect(() => {
  }, [routs])

  return <div className="height-all">{element}</div>
}

export default App