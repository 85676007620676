/*
 * @Author: chenzhi
 * @Date: 2023-03-01 16:38:17
 * @LastEditors: Chen Zhi
 * @LastEditTime: 2024-07-19 11:12:39
 * @Description: 内容reducer模块
 */
import { fromJS } from "immutable"
import { MainAction } from '../interface'

const defaultState: any = fromJS({
  isLoading: false,
})

/**
 * @description: 内容reducer函数
 * @param {*} state 加载
 * @param {*} action
 * @return {*} None
 */
export default (state: any = defaultState, action: MainAction): any => {
    const { type, isLoading } = action
    switch (type) {
      case "setLoading":
        return state.set("isLoading", isLoading);
      default:
        return state
    }
}