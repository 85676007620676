/*
 * @Author: Chen Zhi
 * @Date: 2024-06-13 17:21:50
 * @LastEditors: Chen Zhi
 * @LastEditTime: 2024-06-14 09:50:54
 * @Description: reducers暴露端口
 */

import { combineReducers } from "redux"
import loginReducer from './login'
import mainReducer from './main'

export default combineReducers({
    login: loginReducer,
    main: mainReducer,
})