/*
 * @Author: Chen Zhi
 * @Date: 2024-06-13 16:51:14
 * @LastEditors: Chen Zhi
 * @LastEditTime: 2024-06-14 09:57:23
 * @Description: store 模块
 */
import { legacy_createStore, applyMiddleware, compose } from 'redux'
import thunk from "redux-thunk"
import { persistStore, persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage"
import reducers from './reducers'

const persistConfig = {
    key: "root", // 储存的标识名
    storage, // 储存方式
    whitelist: ["login"], //白名单 模块参与缓存
}

const persistedReducer = persistReducer(persistConfig, reducers)
const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const store = legacy_createStore(
    persistedReducer,
    composeEnhancers(applyMiddleware(thunk))
)
  const persistor = persistStore(store)

export { store, persistor }
