/*
 * @Author: Chen Zhi
 * @Date: 2024-06-13 18:05:35
 * @LastEditors: Chen Zhi
 * @LastEditTime: 2024-06-13 18:08:24
 * @Description: Action类型常量
 */

export default {
    INITSIDEMENUS: "init-side-menus",
    LOGINOUT: "loginout",
    UPDATEMENUS: "update-menus",
    UPDATUSERS: "update-users",
    UPDATROUTES: "update-routes"
}