/*
 * @Author: Chen Zhi
 * @Date: 2024-06-07 14:52:32
 * @LastEditors: Chen Zhi
 * @LastEditTime: 2024-06-14 15:04:12
 * @Description: 入口文件
 */
import { Suspense } from "react"
import ReactDOM from "react-dom/client"
import { BrowserRouter } from "react-router-dom"
import { Provider } from "react-redux"
import { PersistGate } from "redux-persist/integration/react"

import "common/css/reset.css"
import App from "./App"
import { store, persistor } from "./redux/store"
import Loading from "views/other/loading"

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
)

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <Suspense fallback={<Loading />}>
          <App />
        </Suspense>
      </BrowserRouter>
    </PersistGate>
  </Provider>
)
