/*
 * @Author: Chen Zhi
 * @Date: 2024-06-13 17:22:26
 * @LastEditors: Chen Zhi
 * @LastEditTime: 2024-06-14 09:44:40
 * @Description: 登录reducer模块
 */
import { fromJS } from "immutable"
import { LoginAction } from '../interface'
import CT from '../const'

// 默认值
const defaultState = {
    menus: [],
    menuSwtich: {},
    users: {},
    roles: {}
}

/**
 * @description: 登录reducer函数
 * @param {*} state 初始化值
 * @param {IAction} action  传值对象
 * @return {*} None
 */
export default (state: any = defaultState, action: LoginAction): any => {
    let { type, newMenus, menuSwitch, users } = action
    const mapObj = fromJS(state)
    switch (type) {
      case CT.INITSIDEMENUS:
        return mapObj.set("menus", newMenus).set("menuSwitch", menuSwitch).toJS()
      case CT.UPDATUSERS:
        return mapObj.set("users", users).set("roles", users.roles).toJS()
      case CT.LOGINOUT:
        return mapObj.set("users", {}).set("menus", []).set("menuSwitch", {}).set('roles', {}).toJS()
      default:
        return state
    }
}
